import { useAuth0 } from "@auth0/auth0-react";
import { LoginButton } from "../loginButton";
import { LogoutButton } from "../logoutButton";
import { SignupButton } from "../signupButton";
import { Container } from "@mui/material";

export const AuthButtons = () => {
  const { isAuthenticated } = useAuth0();

  return (
    <Container
      sx={{
        flexDirection: { xs: "column", sm: "row" },
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
      }}
    >
      {isAuthenticated && <LogoutButton />}
      {!isAuthenticated && (
        <>
          <LoginButton />
          <SignupButton />
        </>
      )}
    </Container>
  );
};
