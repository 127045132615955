import {
  Button,
  Card,
  CardContent,
  Container,
  SxProps,
  Typography,
} from "@mui/material";
import { LoginButton } from "../loginButton";
import { SignupButton } from "../signupButton";
import { useAuth0 } from "@auth0/auth0-react";
import SchoolIcon from "@mui/icons-material/School";
import FaceIcon from "@mui/icons-material/Face";
import { Link } from "react-router-dom";
import {
  CHEATERS_LINK,
  EXPLORE_SUFFIX,
  HELPERS_LINK,
} from "../../others/constants";

const cardContentSx: SxProps = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "300px",
  height: "200px",
  textAlign: "center",
  gap: "20px",
};
export const AboutABCheatLandingSection = () => {
  const { isAuthenticated } = useAuth0();
  return (
    <Container sx={{ textAlign: "center" }}>
      <Typography
        variant="h3"
        sx={{ fontWeight: "bold", paddingBottom: "2vh" }}
      >
        About ABCheat
      </Typography>
      <Container
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <Card>
          <CardContent sx={cardContentSx}>
            <Typography>
              We guarantee privacy and anonymity for all users.
            </Typography>
            <Button>Read More About Privacy</Button>
          </CardContent>
        </Card>
        <Card>
          <CardContent sx={cardContentSx}>
            <Typography>
              We give students a platform to help each other during exams.
            </Typography>
            <Typography>Try it:</Typography>
            <Container
              sx={{
                flexDirection: "row",
                display: "flex",
                gap: "10px",
                justifyContent: "center",
              }}
            >
              {!isAuthenticated && (
                <>
                  <LoginButton />
                  <SignupButton />
                </>
              )}
              {isAuthenticated && (
                <>
                  <Link to={CHEATERS_LINK + EXPLORE_SUFFIX}>
                    <Button
                      variant="contained"
                      color="secondary"
                      sx={{ color: "white", width: "160px", gap: "10px" }}
                    >
                      <FaceIcon />
                      Cheaters
                    </Button>
                  </Link>
                  <Link to={HELPERS_LINK + EXPLORE_SUFFIX}>
                    <Button
                      variant="contained"
                      color="info"
                      sx={{ color: "white", width: "160px", gap: "10px" }}
                    >
                      <SchoolIcon />
                      Helpers
                    </Button>
                  </Link>
                </>
              )}
            </Container>
          </CardContent>
        </Card>
        <Card>
          <CardContent sx={cardContentSx}>
            <Typography>
              We have already helped thousands of students
            </Typography>
            <Button>Read Our Success Stories</Button>
          </CardContent>
        </Card>
      </Container>
    </Container>
  );
};
