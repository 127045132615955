import { useAuth0 } from '@auth0/auth0-react'
import { Button } from '@mui/material'

export const SignupButton = ({
	variant,
	buttonColor,
	textColor,
}: {
	variant?: 'contained' | 'outlined' | 'text'
	buttonColor?:
		| 'primary'
		| 'secondary'
		| 'info'
		| 'error'
		| 'inherit'
		| 'success'
		| 'warning'
	textColor?: string
}) => {
	const { loginWithRedirect } = useAuth0()

	return (
		<Button
			variant={variant ?? 'outlined'}
			color={buttonColor ?? 'primary'}
			sx={{ color: textColor ?? 'primary', width: '100px' }}
			onClick={() =>
				loginWithRedirect({
					screen_hint: 'signup',
				})
			}>
			Sign Up
		</Button>
	)
}
