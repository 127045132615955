import {
	Button,
	Card,
	CardContent,
	CardHeader,
	CircularProgress,
	Collapse,
	Container,
	Grid,
	List,
	TextField,
	Typography,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { KeyboardArrowUp } from '@mui/icons-material'
import { useState } from 'react'
import { ExamItem } from '../examItem'
import { useSearchExam } from '../../hooks/useSearchExam'
import { getColorBasedOnCheater } from '../../others/utils'

type SearchExamWidgetProps = {
	cheater: boolean
	searchExamURL: string
}

export const SearchExamWidget = ({
	cheater,
	searchExamURL,
}: SearchExamWidgetProps) => {
	const [filterOpen, setFilterOpen] = useState(false)

	const [subject, setSubject] = useState('')
	const [language, setLanguage] = useState('')
	const [duration, setDuration] = useState('')
	const [schoolName, setSchoolName] = useState('')
	const [professor, setProfessor] = useState('')
	const [examInternalId, setExamInternalId] = useState('')
	const [tags, setTags] = useState<string>('')

	const { callApi, exams, loading, error } = useSearchExam()

	return (
		<Card variant='outlined'>
			<CardHeader
				title={
					<Container
						sx={{
							display: 'flex',
							flexDirection: 'row',
							gap: '20px',
							alignItems: 'center',
						}}>
						<Container
							sx={{
								display: 'flex',
								flexDirection: 'row',
								gap: '20px',
								alignItems: 'center',
							}}>
							<Card>
								<CardContent
									sx={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'center',
										alignItems: 'center',
										width: '180px',
									}}>
									<SearchIcon sx={{ color: 'black' }} />
									<Container>
										<Typography>Search Exam</Typography>
										<Typography
											sx={{
												color: 'gray',
												fontSize: 12,
											}}>
											Find Helpers
										</Typography>
									</Container>
								</CardContent>
							</Card>
							<TextField
								sx={{ width: '30%' }}
								variant='outlined'
								label='Subject'
								required
								type='text'
								value={subject}
								onChange={(e) => {
									setSubject(e.target.value)
								}}
							/>
							<TextField
								sx={{ width: '30%' }}
								required
								type='text'
								label='Language'
								value={language}
								onChange={(e) => {
									setLanguage(e.target.value)
								}}
							/>

							<Button
								sx={{
									width: '30%',
									color: 'white',
									gap: '10px',
								}}
								variant='contained'
								color={getColorBasedOnCheater(cheater)}
								onClick={() => {
									const exam = {
										subject,
										language,
										duration: Number(duration) ?? 0,
										cheater: !cheater, // If the user is a cheater, they are looking for exams by helpers
										schoolName,
										professor,
										examInternalId,
										tags,
									}
									callApi({
										url: searchExamURL,
										data: exam,
									})
								}}>
								<SearchIcon />
								Search
							</Button>
						</Container>
						<Button
							onClick={() => {
								setFilterOpen(!filterOpen)
							}}>
							{filterOpen && (
								<KeyboardArrowUp sx={{ color: 'black' }} />
							)}
							{!filterOpen && (
								<KeyboardArrowDownIcon
									sx={{ color: 'black' }}
								/>
							)}
						</Button>
					</Container>
				}
			/>
			<Collapse in={filterOpen}>
				<CardContent sx={{ border: 0.5, opacity: '0.7' }}>
					<Grid container spacing={2} sx={{ alignItems: 'center' }}>
						<Grid item xs={3}>
							<TextField
								required
								type='text'
								label='Duration'
								value={duration}
								onChange={(e) => {
									setDuration(e.target.value)
								}}
							/>
						</Grid>
						<Grid item xs={3}>
							<TextField
								required
								type='text'
								label='School Name'
								value={schoolName}
								onChange={(e) => {
									setSchoolName(e.target.value)
								}}
							/>
						</Grid>
						<Grid item xs={3}>
							<TextField
								required
								type='text'
								label='Professor'
								value={professor}
								onChange={(e) => {
									setProfessor(e.target.value)
								}}
							/>
						</Grid>
						<Grid item xs={3}>
							<TextField
								required
								type='text'
								label='Exam Internal ID'
								value={examInternalId}
								onChange={(e) => {
									setExamInternalId(e.target.value)
								}}
							/>
						</Grid>
						<Grid item xs={3}>
							<TextField
								required
								type='text'
								label='Other tags'
								value={tags}
								onChange={(e) => {
									setTags(e.target.value)
								}}
							/>
						</Grid>
					</Grid>
				</CardContent>
			</Collapse>
			<CardContent>
				<List>
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						{error && !loading && (
							<Typography>{'Not Found'}</Typography>
						)}
						{loading && <CircularProgress />}
					</div>
					{!error &&
						exams &&
						exams.map((exam) => {
							return (
								<ExamItem
									key={
										exam.pk_subject_language +
										exam.sk_ts_userId
									}
									{...exam}
									cheater={!cheater}
								/>
							)
						})}
				</List>
			</CardContent>
		</Card>
	)
}
