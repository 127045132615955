import { useAuth0 } from '@auth0/auth0-react'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { FaDiscord } from 'react-icons/fa'
import {
	Button,
	Card,
	CardContent,
	CardHeader,
	CircularProgress,
	Collapse,
	Container,
	Grid,
	TextField,
	Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useCreateExam } from '../../hooks/useCreateExam'
import { ExamData } from '../../others/types'
import './styles.css'
import { getColorBasedOnCheater } from '../../others/utils'
import { useSetDiscordUsername } from '../../hooks/useSetDiscordUsername'
import { API_URL } from '../../others/constants'
import { useGetDiscordUsername } from '../../hooks/useGetDiscordUsername'

type AddNewExamWidgetProps = {
	cheater: boolean
}

export const AddNewExamWidget = ({ cheater }: AddNewExamWidgetProps) => {
	const [open, setOpen] = useState(false)
	const { user } = useAuth0()
	const [subject, setSubject] = useState('')
	const [duration, setDuration] = useState('')
	const [language, setLanguage] = useState('') // TODO: add language selection [en, ro, fr, de, ...
	const [description, setDescription] = useState('')
	const [schoolName, setSchoolName] = useState('')
	const [professorName, setProfessorName] = useState('')
	const [examInternalId, setExamInternalId] = useState('')
	const [tags, setTags] = useState<string>('')
	const [discordUsername, setDiscordUsername] = useState('')
	const [price, setPrice] = useState('')
	const [priceUnit, setPriceUnit] = useState('')

	const {
		callApi: callApiSetDiscordUsername,
		data: dataSetDiscordUsername,
		loading: loadingSetDiscordUsername,
		error: errorSetDiscordUsername,
	} = useSetDiscordUsername()

	const {
		callApi: callApiGetDiscordUsername,
		data: dataGetDiscordUsername,
		loading: loadingGetDiscordUsername,
		error: errorGetDiscordUsername,
	} = useGetDiscordUsername()
	useEffect(() => {
		callApiGetDiscordUsername({
			url: API_URL + '/getDiscordUsername',
			data: {
				email: user?.email ?? '',
			},
		})
	}, [])
	useEffect(() => {
		if (dataGetDiscordUsername) {
			setDiscordUsername(dataGetDiscordUsername.discordUsername)
		}
	}, [dataGetDiscordUsername])

	const {
		callApi: callApiCreateExam,
		data: dataCreateExam,
		loading: loadingCreateExam,
		error: errorCreateExam,
	} = useCreateExam()

	return (
		<Card variant='outlined'>
			<CardHeader
				title={
					<Container>
						<Button
							fullWidth
							sx={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
								alignItems: 'center',
							}}
							color={getColorBasedOnCheater(cheater)}
							onClick={() => setOpen(!open)}>
							<Container>
								<Typography sx={{ color: 'black' }}>
									Add new exam
								</Typography>
								{cheater && (
									<Typography
										sx={{ color: 'gray', fontSize: 12 }}>
										Find Helpers
									</Typography>
								)}
								{!cheater && (
									<Typography
										sx={{ color: 'gray', fontSize: 12 }}>
										Find Cheaters
									</Typography>
								)}
							</Container>
							{open && <RemoveIcon sx={{ color: 'black' }} />}
							{!open && <AddIcon sx={{ color: 'black' }} />}
						</Button>
					</Container>
				}
			/>

			<Collapse in={open}>
				<CardContent sx={{ display: 'grid', flexDirection: 'column' }}>
					<Grid container spacing={2} sx={{ alignItems: 'center' }}>
						<Grid item xs={3}>
							<TextField
								required
								type='text'
								label='Subject'
								value={subject}
								onChange={(e) => setSubject(e.target.value)}
							/>
						</Grid>
						<Grid item xs={3}>
							<TextField
								required
								type='text'
								label='Price in €'
								value={price}
								onChange={(e) => setPrice(e.target.value)}
							/>
						</Grid>
						<Grid item xs={3}>
							<TextField
								required
								type='text'
								label='Duration in minutes'
								value={duration}
								onChange={(e) => setDuration(e.target.value)}
							/>
						</Grid>

						<Grid item xs={3}>
							<TextField
								required
								type='text'
								label='Language'
								value={language}
								onChange={(e) => setLanguage(e.target.value)}
							/>
						</Grid>
						<Grid item xs={3}>
							<TextField
								className='discord-input'
								required
								type='text'
								value={discordUsername}
								onChange={(e) =>
									setDiscordUsername(e.target.value)
								}
								label={
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
											gap: '10px',
										}}>
										<Typography>Username</Typography>
										<FaDiscord size={30} />
									</div>
								}
							/>
						</Grid>
						<Grid item xs={3}>
							<Link
								to='https://discord.com/'
								style={{
									textDecoration: 'none',
								}}
								target={'_blank'}>
								<Button
									sx={{
										display: 'flex',
										flexDirection: 'row',
										gap: '10px',
										alignItems: 'center',
										justifyContent: 'end',
									}}
									color={getColorBasedOnCheater(cheater)}>
									<Typography>Go</Typography>
									<FaDiscord size={30} />
								</Button>
							</Link>
						</Grid>
						<Grid item xs={12}>
							<TextField
								fullWidth
								multiline
								required
								type='text'
								label='Content Description'
								value={description}
								onChange={(e) => setDescription(e.target.value)}
							/>
						</Grid>
						<Grid item xs={3}>
							<TextField
								type='text'
								label='School Name'
								value={schoolName}
								onChange={(e) => setSchoolName(e.target.value)}
							/>
						</Grid>
						<Grid item xs={3}>
							<TextField
								type='text'
								label='Professor Name'
								value={professorName}
								onChange={(e) =>
									setProfessorName(e.target.value)
								}
							/>
						</Grid>
						<Grid item xs={3}>
							<TextField
								type='text'
								label='Exam Internal ID'
								value={examInternalId}
								onChange={(e) =>
									setExamInternalId(e.target.value)
								}
							/>
						</Grid>
						<Grid item xs={3}>
							<TextField
								type='text'
								label='Tags'
								value={tags}
								onChange={(e) => setTags(e.target.value)}
							/>
						</Grid>
						<Grid item xs={12}>
							<Button
								variant='contained'
								color={getColorBasedOnCheater(cheater)}
								sx={{
									color: 'white',
									width: '100%',
									height: '50px',
								}}
								component='label'
								onClick={async () => {
									const exam: ExamData = {
										subject,
										duration: parseInt(duration),
										language,
										description,
										price: parseInt(price),
										priceUnit,
										discordUsername,
										schoolName,
										professor: professorName,
										examInternalId,
										tags: tags,
										cheater,
									}
									callApiCreateExam({
										url: API_URL + '/postExam',
										data: {
											...exam,
											email: user?.email ?? '',
										},
									})

									callApiSetDiscordUsername({
										url: API_URL + '/setDiscordUsername',
										data: {
											email: user?.email ?? '',
											discordUsername,
										},
									})
								}}>
								{loadingCreateExam && <CircularProgress />}
								{!loadingCreateExam && (
									<>
										<AddIcon />
										<Typography>Add new Exam</Typography>
									</>
								)}
							</Button>
							<div
								style={{
									textAlign: 'center',
									paddingTop: '2vh',
								}}>
								{errorCreateExam && (
									<Typography>Error, try again</Typography>
								)}
								{dataCreateExam && (
									<Typography>
										Exam added successfully
									</Typography>
								)}
							</div>
						</Grid>
					</Grid>
				</CardContent>
			</Collapse>
		</Card>
	)
}
