import { ExamDataWithKeys, ExamQuerySchema } from "./../others/types";
import { useFetchJson } from "./useFetch";

export const useSearchExam = () => {
  const { callApi, data, loading, error } = useFetchJson<
    ExamQuerySchema,
    { exams: ExamDataWithKeys[] }
  >();

  return { callApi, exams: data.exams, loading, error };
};
