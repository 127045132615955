import { Container } from '@mui/material'
import { AddNewExamWidget } from '../../components/addNewExam'
import { SearchExamWidget } from '../../components/searchExam'
import { API_URL } from '../../others/constants'

export const CheatersExplorePage = () => {
	return (
		<Container
			sx={{ display: 'flex', flexDirection: 'column', gap: '7vh' }}>
			<AddNewExamWidget
				cheater={true}
			/>
			<SearchExamWidget
				cheater={true}
				searchExamURL={API_URL + '/findExams'}
			/>
		</Container>
	)
}
