import { ExamData } from './../others/types'
import { useFetchJson } from './useFetch'

export const useCreateExam = () => {
	const { callApi, data, loading, error } = useFetchJson<
		ExamData & { email: string },
		{ message: string }
	>()

	return { callApi, data: data.message, loading, error }
}
