import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@mui/material";

export const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <Button
      variant="contained"
      color="error"
      sx={{ color: "white", width: "100px" }}
      onClick={() => logout({ returnTo: window.location.origin })}
    >
      Log out
    </Button>
  );
};
