import React from "react";

/**
 * Application state interface
 */
export interface AppState {
	authError?: boolean
	updateState: (newState: Partial<AppState>) => void
}

/**
 * Default application state
 */
const defaultState: AppState = {
	authError: false,
	updateState: (newState?: Partial<AppState>) => {},
};

/**
 * Creating the Application state context for the provider
 */
export const Context = React.createContext<AppState>(defaultState);