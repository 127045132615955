export const LINKEDIN_URL =
	'https://www.linkedin.com/in/matteo-librizzi-450465180/'
export const GITHUB_URL = 'https://github.com/MatteoLibrizzi'
export const MAIL_ADDRESS_URL = 'mailto:librizzimatteo.ml@gmail.com'
export const CHEATERS_LINK = '/cheaters'
export const HELPERS_LINK = '/helpers'
export const INFO_SUFFIX = '/info'
export const EXPLORE_SUFFIX = '/explore'
const DEV_API_URL =
	'https://5mknciznmc.execute-api.eu-west-1.amazonaws.com/prod'

const PROD_API_URL =
	'https://eyfv4f8hh7.execute-api.eu-west-1.amazonaws.com/prod'

export const API_URL =
	process.env.REACT_APP_ENV === 'prod' ? PROD_API_URL : DEV_API_URL

const DEV_STRIPE_API_URL =
	'https://u8nr0vjwik.execute-api.us-east-1.amazonaws.com/prod'
const PROD_STRIPE_API_URL =
	'https://ed9j1wqfek.execute-api.eu-west-1.amazonaws.com/prod'

export const STRIPE_API_URL =
	process.env.REACT_APP_ENV === 'prod'
		? PROD_STRIPE_API_URL
		: DEV_STRIPE_API_URL

const DEV_AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN_DEV || ''
const DEV_AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID_DEV || ''
const DEV_AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE_DEV || ''

const PROD_AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN_PROD || ''
const PROD_AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID_PROD || ''
const PROD_AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE_PROD || ''

export const AUTH0_DOMAIN =
	process.env.REACT_APP_ENV === 'prod' ? PROD_AUTH0_DOMAIN : DEV_AUTH0_DOMAIN

export const AUTH0_CLIENT_ID =
	process.env.REACT_APP_ENV === 'prod'
		? PROD_AUTH0_CLIENT_ID
		: DEV_AUTH0_CLIENT_ID

export const AUTH0_AUDIENCE =
	process.env.REACT_APP_ENV === 'prod'
		? PROD_AUTH0_AUDIENCE
		: DEV_AUTH0_AUDIENCE

const LOCAL_URL = 'http://localhost:3000'
const DEV_URL = 'https://dev.abcheat.net'
const PROD_URL = 'https://www.abcheat.net'

export const BASE_URL =
	process.env.REACT_APP_ENV === 'prod'
		? PROD_URL
		: process.env.REACT_APP_ENV === 'dev'
		? DEV_URL
		: LOCAL_URL
