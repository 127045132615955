import { Typography } from "@mui/material";
import { Logo } from "../logo";
import "./styles.css";
import LockIcon from "@mui/icons-material/Lock";
import { LOGO_LABEL } from "../../text";


export const LandingIntroduction = () => {
  return (
    <div className="personal-introduction">
      <div className="flex-row-center">
        <Logo />
        <h1 style={{ paddingLeft: 10, paddingRight: 10 }} className="name">
          ABCheat
        </h1>
        <LockIcon className="lock-icon" />
      </div>
      <Typography>{LOGO_LABEL}</Typography>
    </div>
  );
};
