import { useAuth0 } from '@auth0/auth0-react'
import { Button } from '@mui/material'

export const LoginButton = ({
	buttonColor,
	variant,
	textColor,
}: {
	variant?: 'contained' | 'outlined' | 'text'
	buttonColor?:
		| 'primary'
		| 'secondary'
		| 'info'
		| 'error'
		| 'inherit'
		| 'success'
		| 'warning'
	textColor?: string
}) => {
	const { loginWithRedirect } = useAuth0()
	return (
		<Button
			variant={variant ?? 'contained'}
			color={buttonColor ?? 'primary'}
			sx={{ color: textColor ?? 'white', width: '100px' }}
			onClick={() => {
				loginWithRedirect({
					appState: { returnTo: window.location.pathname },
				})
			}}>
			Log in
		</Button>
	)
}
