import { ExamDataWithKeys } from './../others/types'
import { useFetchJson } from './useFetch'

export const useGetExamByKeys = () => {
	const { callApi, data, loading, error } = useFetchJson<
		{ pk_subject_language: string; sk_ts_userId: string },
		{ exam: ExamDataWithKeys | undefined }
	>()

	return { callApi, data: data.exam, loading, error }
}
