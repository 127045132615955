import { Button, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

type DrawerItemProps = {
  to?: string;
  text?: React.ReactNode;
  button?: boolean;
  icon?: React.ReactNode;
  color?: "primary" | "secondary" | "info";
  children?: React.ReactNode;
};
export const DrawerItem = ({
  to,
  text,
  icon,
  color,
  button = true,
  children,
}: DrawerItemProps) => {
  const LinkComponent = to ? Link : "div";
  const ButtonComponent = button ? Button : "div";
  const ChildrenComponent = children ? (
    children
  ) : (
    <>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={text} />
    </>
  );
  return (
    <ListItem>
      <LinkComponent
        to={to ?? ""}
        style={{ textDecoration: "none", color: "inherit", width: "100%" }}
      >
        <ButtonComponent
          variant="contained"
          color={color}
          sx={{ width: "100%" }}
        >
          {ChildrenComponent}
        </ButtonComponent>
      </LinkComponent>
    </ListItem>
  );
};
