import { Container, Typography, Button } from '@mui/material'
import { FaDiscord } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { HELPERS_LINK, EXPLORE_SUFFIX, CHEATERS_LINK } from '../../others/constants'
import './styles.css'

export const CheatersInfoPage = () => {
	return (
		<Container
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'start',
				alignItems: 'center',
				paddingTop: '2vh',
				paddingBottom: '1vh',
				minHeight: '80vh',
				gap: '1vh',
			}}>
			<Typography variant='h4'>Helpers Info Page</Typography>
			<Typography>
				As a cheater you are a student and would like help from someone
				to pass an exam
			</Typography>
			<Typography>Find helpers that can... help you</Typography>
			<Typography>
				They will share that they can help, or you can publish your
				offer by publishing an exam
			</Typography>
			<Container
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'center',
					alignItems: 'center',
					gap: '1vw',
				}}>
				<Typography>
					Then you'll be able to communicate via Discord
				</Typography>
				<FaDiscord />
			</Container>
			<Link to={CHEATERS_LINK + EXPLORE_SUFFIX}>
				<Button
					variant='contained'
					color='secondary'
					style={{ color: 'white' }}>
					<Typography>Go</Typography>
				</Button>
			</Link>
		</Container>
	)
}
