import { useFetchJson } from './useFetch'

export const useGetDiscordUsername = () => {
	const { callApi, data, loading, error } = useFetchJson<
		{ email: string },
		{ discordUsername: string }
	>()

	return { callApi, data, loading, error }
}
