import { useFetchJson } from './useFetch'

export const useChechoutSession = () => {
	const { callApi, data, loading, error } = useFetchJson<
		{
			discordUsernameHelper: string
			discordUsernameCheater: string
			examSubject: string
			price: number
			successUrl: string
			cancelUrl: string
		},
		{ redirectUrl: string }
	>()

	return { callApi, data, loading, error }
}
