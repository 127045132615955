import { Button, Container, Typography } from '@mui/material'
import './styles.css'
import { FaDiscord } from 'react-icons/fa'
import {
	EXPLORE_SUFFIX,
	HELPERS_LINK,
} from '../../others/constants'
import { Link } from 'react-router-dom'

export const HelpersInfoPage = () => {
	return (
		<Container
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'start',
				alignItems: 'center',
				paddingTop: '2vh',
				paddingBottom: '1vh',
				minHeight: '80vh',
				gap: '1vh',
			}}>
			<Typography variant='h4'>Helpers Info Page</Typography>
			<Typography>
				As an helper you have the skills to help a student pass an exam
			</Typography>
			<Typography>Find students that need help</Typography>
			<Typography>
				They will share that they need help, or you can offer to help by
				publishing an exam
			</Typography>
			<Container
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'center',
					alignItems: 'center',
					gap: '1vw',
				}}>
				<Typography>
					Then you'll be able to communicate via Discord
				</Typography>
				<FaDiscord />
			</Container>
			<Link to={HELPERS_LINK + EXPLORE_SUFFIX}>
				<Button
					variant='contained'
					color='info'
					style={{ color: 'white' }}>
					<Typography>Go</Typography>
				</Button>
			</Link>
		</Container>
	)
}
