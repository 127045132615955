import { Outlet } from 'react-router-dom'
import { Footer } from '../components/footer'
import { BottomNavigation, Box, Container, List } from '@mui/material'
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material'
import { CustomDrawer } from './drawer'
import { Contacts } from '../components/contacts'
import { DrawerItem } from './drawerItem'
import HomeIcon from '@mui/icons-material/Home'
import SchoolIcon from '@mui/icons-material/School'
import FaceIcon from '@mui/icons-material/Face'
import SearchIcon from '@mui/icons-material/Search'
import { AuthButtons } from '../components/authButtons'
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd'

import {
	CHEATERS_LINK,
	EXPLORE_SUFFIX,
	HELPERS_LINK,
	INFO_SUFFIX,
} from '../others/constants'
import { UserInfo } from './UserInfo'
import { HeaderTitle } from '../components/headerTitle'
import { useContext } from 'react'
import { Context } from '../context'
import { LoginButton } from '../components/loginButton'

const LayoutDefault = () => {
	const DrawerContent = () => {
		return (
			<Box
				role='presentation'
				sx={{
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}>
				<Box>
					<List>
						<DrawerItem
							to='/'
							text={<Typography color={'white'}>Home</Typography>}
							icon={<HomeIcon sx={{ color: 'white' }} />}
							color='primary'
						/>
						<DrawerItem
							to='/saved'
							text={
								<Typography color={'white'}>Saved</Typography>
							}
							icon={<BookmarkAddIcon sx={{ color: 'white' }} />}
							color='primary'
						/>
						<DrawerItem
							to={CHEATERS_LINK + INFO_SUFFIX}
							text={
								<Typography color={'white'}>
									Cheaters Info
								</Typography>
							}
							icon={<FaceIcon sx={{ color: 'white' }} />}
							color='secondary'
						/>
						<DrawerItem
							to={CHEATERS_LINK + EXPLORE_SUFFIX}
							text={
								<Typography color={'white'}>
									Cheaters Explore
								</Typography>
							}
							icon={<SearchIcon sx={{ color: 'white' }} />}
							color='secondary'
						/>
						<DrawerItem
							to={HELPERS_LINK + INFO_SUFFIX}
							text={
								<Typography color={'white'}>
									Helpers Info
								</Typography>
							}
							icon={<SchoolIcon sx={{ color: 'white' }} />}
							color='info'
						/>
						<DrawerItem
							to={HELPERS_LINK + EXPLORE_SUFFIX}
							text={
								<Typography color={'white'}>
									Helpers Explore
								</Typography>
							}
							icon={<SearchIcon sx={{ color: 'white' }} />}
							color='info'
						/>
						<DrawerItem button={false}>
							<UserInfo />
						</DrawerItem>
						<DrawerItem button={false} color='primary'>
							<AuthButtons />
						</DrawerItem>
					</List>
				</Box>
				<Box>
					<Contacts />
				</Box>
			</Box>
		)
	}

	const { authError } = useContext(Context)

	// TODO fix bug with toolback smaller than 600px
	return (
		<>
			<AppBar position='sticky'>
				<Toolbar sx={{ height: '70px' }}>
					<Container
						sx={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'start',
							justifyContent: 'space-between',
						}}>
						<HeaderTitle color={'white'} />
						{authError && (
							<Container
								sx={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
								}}>
								<Typography>
									Error with authentication
								</Typography>
								<LoginButton
									variant='contained'
									buttonColor='error'
								/>
							</Container>
						)}
						<IconButton size='small' color='inherit'>
							<CustomDrawer>
								<DrawerContent />
							</CustomDrawer>
						</IconButton>
					</Container>
				</Toolbar>
			</AppBar>

			<Outlet />
			<BottomNavigation>
				<Footer />
			</BottomNavigation>
		</>
	)
}

export default LayoutDefault
