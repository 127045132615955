import {
	Button,
	Card,
	CardContent,
	CardHeader,
	CircularProgress,
	Collapse,
	Container,
	ListItem,
	Tooltip,
	Typography,
} from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { KeyboardArrowUp } from '@mui/icons-material'
import { useState } from 'react'
import { ExamDataWithKeys } from '../../others/types'
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd'
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove'
import { useCookies } from 'react-cookie'
import { getColorBasedOnCheater } from '../../others/utils'
import { Link } from 'react-router-dom'

type ExamItemProps = ExamDataWithKeys & {
	cheater: boolean
	loading?: boolean
}

export const ExamItem = ({
	cheater,
	pk_subject_language,
	sk_ts_userId,
	subject,
	language,
	duration,
	price,
	priceUnit,
	description,
	discordUsername,
	schoolName,
	professor,
	tags,
	loading,
}: ExamItemProps) => {
	const [cookies, setCookie] = useCookies([
		'savedExamsCheaters',
		'savedExamsHelpers',
	])
	const saveExamInCookies = (
		pk_subject_language: string,
		sk_ts_userId: string
	) => {
		if (cheater) {
			const previousSavedExams = cookies.savedExamsCheaters ?? []
			const newlySavedExams = new Set([
				...previousSavedExams,
				JSON.stringify({ pk_subject_language, sk_ts_userId }),
			])
			console.log(Array.from(newlySavedExams.values()))
			setCookie('savedExamsCheaters', [
				Array.from(newlySavedExams.values()),
			])
		} else {
			const previousSavedExams = cookies.savedExamsHelpers ?? []
			const newlySavedExams = new Set([
				...previousSavedExams,
				JSON.stringify({ pk_subject_language, sk_ts_userId }),
			])
			console.log(Array.from(newlySavedExams.values()))
			setCookie('savedExamsHelpers', Array.from(newlySavedExams.values()))
		}
	}

	const removeExamFromCookies = (
		examSubjectLanguage: string,
		examTsUserid: string
	) => {
		try {
			let savedExams = cookies.savedExamsCheaters ?? []
			let exam = savedExams.find(
				(examString: string) =>
					JSON.parse(examString).pk_subject_language ===
						examSubjectLanguage &&
					JSON.parse(examString).sk_ts_userId === examTsUserid
			)
			if (exam) {
				let index = cookies.savedExamsCheaters.indexOf(exam)
				cookies.savedExamsCheaters.splice(index, 1)
				setCookie('savedExamsCheaters', cookies.savedExamsCheaters)
				return
			}
			savedExams = cookies.savedExamsHelpers ?? []
			exam = savedExams.find(
				(examString: string) =>
					JSON.parse(examString).pk_subject_language ===
						examSubjectLanguage &&
					JSON.parse(examString).sk_ts_userId === examTsUserid
			)
			if (exam) {
				let index = cookies.savedExamsHelpers.indexOf(exam)
				cookies.savedExamsHelpers.splice(index, 1)
				setCookie('savedExamsHelpers', cookies.savedExamsHelpers)
				return
			}
		} catch (e) {
			console.error(e)
		}
	}

	const isExamSaved =
		cookies.savedExamsCheaters?.find(
			(examString: string) =>
				JSON.parse(examString).pk_subject_language ===
					pk_subject_language &&
				JSON.parse(examString).sk_ts_userId === sk_ts_userId
		) ||
		cookies.savedExamsHelpers?.find(
			(examString: string) =>
				JSON.parse(examString).pk_subject_language ===
					pk_subject_language &&
				JSON.parse(examString).sk_ts_userId === sk_ts_userId
		)

	const [moreInfoOpen, setMoreInfoOpen] = useState(false)
	const [copied, setCopied] = useState(false)

	setTimeout(() => {
		setCopied(false)
	}, 2000)
	return (
		<ListItem>
			{loading && <CircularProgress />}
			{!loading && (
				<Card variant='outlined' sx={{ width: '100%' }}>
					<>
						<CardHeader
							title={
								<>
									<Container
										sx={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
											gap: '10px',
										}}>
										<Tooltip
											title={
												<Container
													sx={{
														textAlign: 'center',
													}}>
													<Typography>
														This exam was published
														by{' '}
														{!cheater
															? 'a cheater'
															: 'an helper'}
														.
													</Typography>
													{!cheater && (
														<Typography
															fontWeight={'bold'}>
															Can you help them?
														</Typography>
													)}
													{cheater && (
														<Typography
															fontWeight={'bold'}>
															Can they help you?
														</Typography>
													)}
												</Container>
											}>
											<InfoIcon />
										</Tooltip>
										<Link
											to={`/exam/${pk_subject_language}/${sk_ts_userId}`}>
											<Button
												variant='contained'
												color={
													!cheater
														? 'secondary'
														: 'info'
												}
												sx={{
													color: 'white',
												}}>
												Go
											</Button>
										</Link>
										<Container
											sx={{
												display: 'flex',
												flexDirection: 'row',
												alignItems: 'center',
												width: '80%',
											}}>
											<Typography fontWeight={'bold'}>
												{subject}
											</Typography>
											<Typography>
												&nbsp;-&nbsp;
											</Typography>
											<Typography>{language}</Typography>
											{schoolName && (
												<>
													<Typography>
														&nbsp;-&nbsp;
													</Typography>
													<Typography>
														{schoolName}
													</Typography>
												</>
											)}
											<Button
												onClick={() => {
													setMoreInfoOpen(
														!moreInfoOpen
													)
												}}>
												{moreInfoOpen && (
													<KeyboardArrowUp
														sx={{ color: 'black' }}
													/>
												)}
												{!moreInfoOpen && (
													<KeyboardArrowDownIcon
														sx={{ color: 'black' }}
													/>
												)}
											</Button>
										</Container>
										<Container
											sx={{
												display: 'inline-grid',
												gridTemplateColumns: 'auto 50%',
												gap: '10px',
											}}>
											<Button
												color={getColorBasedOnCheater(
													!cheater
												)}
												sx={{
													color: 'white',
													gap: '10px',
												}}
												onClick={() => {
													navigator.clipboard.writeText(
														discordUsername
													)
													setCopied(true)
												}}
												variant='contained'>
												{!copied && (
													<Typography>
														Copy Chat Id
													</Typography>
												)}
												{copied && (
													<Typography>
														Copied!
													</Typography>
												)}
											</Button>
											{!isExamSaved && (
												<Button
													color={getColorBasedOnCheater(
														!cheater
													)}
													sx={{
														color: 'white',
														gap: '10px',
													}}
													onClick={() => {
														saveExamInCookies(
															pk_subject_language,
															sk_ts_userId
														)
													}}
													variant='contained'>
													<Typography>
														Save for later
													</Typography>
													<BookmarkAddIcon />
												</Button>
											)}
											{isExamSaved && (
												<Button
													color={getColorBasedOnCheater(
														!cheater
													)}
													sx={{
														color: 'white',
														gap: '10px',
													}}
													onClick={() => {
														removeExamFromCookies(
															pk_subject_language,
															sk_ts_userId
														)
													}}
													variant='contained'>
													<Typography>
														Remove
													</Typography>
													<BookmarkRemoveIcon />
												</Button>
											)}
										</Container>
									</Container>
								</>
							}
						/>
						<Collapse in={moreInfoOpen}>
							<CardContent>
								{professor && (
									<Typography>
										Professor: {professor}
									</Typography>
								)}
								<Typography>
									Price: {price}
									{priceUnit}
								</Typography>
								<Typography>Duration: {duration}</Typography>
								<Typography>
									Description: {description}
								</Typography>
								<Typography>Tags: {tags}</Typography>
								<Typography>
									Discord username: {discordUsername}
								</Typography>
							</CardContent>
						</Collapse>
					</>
				</Card>
			)}
		</ListItem>
	)
}
