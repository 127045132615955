import { useAuth0 } from '@auth0/auth0-react'
import { useCallback, useContext, useState } from 'react'
import { Context } from '../context'

export type CallApiProps<T> = {
	url: string
	data: T
}

export const useFetchJson = <T, V>() => {
	const [data, setData] = useState<V>({} as V)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(false)

	const { getAccessTokenWithPopup, isAuthenticated, getAccessTokenSilently } =
		useAuth0()

	const globalState = useContext(Context)

	const callApi = useCallback(
		async ({ url, data }: CallApiProps<T>) => {
			setLoading(true)
			let bearerFunction
			if (!isAuthenticated) {
				bearerFunction = getAccessTokenWithPopup
			} else {
				bearerFunction = getAccessTokenSilently
			}

			let bearer = ''
			try {
				bearer = await bearerFunction()
				globalState.updateState({ ...globalState, authError: false })
			} catch (error) {
				setError(true)
				setLoading(false)
				console.error(error)
				globalState.updateState({ ...globalState, authError: true })
				return
			}

			console.log('Sendin request with ', data)

			await fetch(url, {
				method: 'POST',
				body: JSON.stringify(data),
				headers: {
					Authorization: `Bearer ${bearer}`,
				},
			})
				.then((response) => {
					if (!response.ok) throw new Error('Error fetching')
					setError(false)
					return response.json()
				})
				.then((data) => setData(data))
				.catch(() => setError(true))
				.finally(() => setLoading(false))
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[isAuthenticated]
	)

	return { callApi, data, loading, error }
}
