import { ThemeProvider, createTheme } from '@mui/material'
import { blue, green, orange } from '@mui/material/colors'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './App.css'
import Auth0ProviderWithHistory from './auth/auth0ProviderWithHistory'
import CheatersLayout from './layouts/cheatersLayout'
import LayoutDefault from './layouts/defaultLayout'
import HelpersLayout from './layouts/helpersLayout'
import {
	CHEATERS_LINK,
	EXPLORE_SUFFIX,
	HELPERS_LINK,
	INFO_SUFFIX,
} from './others/constants'
import { CheatersInfoPage } from './pages/cheatersInfoPage'
import { HelpersInfoPage } from './pages/helpersInfoPage'
import { LandingPage } from './pages/landing'
import './webkit.css'
import { HelpersExplorePage } from './pages/helpersExplorePage'
import { CheatersExplorePage } from './pages/cheatersExplorePage'
import { SavedPage } from './pages/savedPage'
import { ExamPage } from './pages/examPage'
import { SuccessPage } from './pages/success'

const theme = createTheme({
	palette: {
		primary: blue,
		secondary: orange,
		info: green,
	},
})

function App() {
	return (
		<ThemeProvider theme={theme}>
			<BrowserRouter>
				<Auth0ProviderWithHistory>
					<Routes>
						<Route path='/' element={<LayoutDefault />}>
							<Route index element={<LandingPage />} />
							<Route path='/saved' element={<SavedPage />} />
							<Route
								path='/exam/:languageSubject/:tsUserId'
								element={<ExamPage />}
							/>
							<Route path='/success' element={<SuccessPage />} />
						</Route>
						<Route
							path={CHEATERS_LINK}
							element={<CheatersLayout />}>
							<Route
								index
								path={CHEATERS_LINK + INFO_SUFFIX}
								element={<CheatersInfoPage />}
							/>
							<Route
								path={CHEATERS_LINK + EXPLORE_SUFFIX}
								element={<CheatersExplorePage />}
							/>
						</Route>
						<Route path={HELPERS_LINK} element={<HelpersLayout />}>
							<Route
								index
								path={HELPERS_LINK + INFO_SUFFIX}
								element={<HelpersInfoPage />}
							/>
							<Route
								path={HELPERS_LINK + EXPLORE_SUFFIX}
								element={<HelpersExplorePage />}
							/>
						</Route>
					</Routes>
				</Auth0ProviderWithHistory>
			</BrowserRouter>
		</ThemeProvider>
	)
}

export default App
