import { useFetchJson } from './useFetch'

export const useSetDiscordUsername = () => {
	const { callApi, data, loading, error } = useFetchJson<
		{ email: string; discordUsername: string },
		{}
	>()

	return { callApi, data, loading, error }
}
