import { useAuth0 } from "@auth0/auth0-react";
import { Box, Container, Typography } from "@mui/material";

export const UserInfo = () => {
  const { user, isAuthenticated } = useAuth0();

  return (
    <>
      {isAuthenticated && (
        <Container
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box
            component={"img"}
            sx={{ width: 45, height: 45 }}
            src={user?.picture}
          />
          <Container>
            <Typography>{user?.given_name}</Typography>
            <Typography fontSize={12}>{user?.email}</Typography>
          </Container>
        </Container>
      )}
    </>
  );
};
