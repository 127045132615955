import { ForCheatersForHelpersLandingSection } from "../../components/infoWithImages";
import { AboutABCheatLandingSection } from "../../components/pagesPresentation";
import { LandingIntroduction } from "../../components/personalIntroduction";
import "./styles.css";

export const LandingPage = () => {
  return (
    <div className="landing-page">
      <div className="section">
        <LandingIntroduction />
      </div>
      <div className="section">
        <ForCheatersForHelpersLandingSection />
      </div>
      <div className="section">
        <AboutABCheatLandingSection />
      </div>
    </div>
  );
};
