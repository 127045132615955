import { IconButton, Drawer } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import React from "react";
type CustomDrawerProps = {
  children: React.ReactNode;
};

export const CustomDrawer = ({ children }: CustomDrawerProps) => {
  const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };
  return (
    <IconButton onClick={toggleDrawer} size="small">
      <Drawer open={drawerOpen} anchor="right" onClose={toggleDrawer}>
        {children}
      </Drawer>

      <MenuIcon />
    </IconButton>
  );
};
