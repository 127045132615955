import {
	Box,
	CircularProgress,
	CircularProgressProps,
	Container,
	Typography,
} from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

export const SuccessPage = () => {
	setTimeout(() => {
		window.location.href = '/'
	}, 5000)
	return (
		<Container
			sx={{
				display: 'flex',
				flexDirection: 'column',
				paddingTop: '3vh',
				justifyContent: 'start',
				alignItems: 'center',
				gap: '10vh',
				minHeight: '80vh',
			}}>
			<Container
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'center',
					gap: '3vw',
				}}>
				<CheckCircleIcon color='success' sx={{ fontSize: 40 }} />
				<Typography>
					Your payment was successful! You'll be redirected in a few
					seconds.
				</Typography>
			</Container>
			<Typography>
				Check your email for confirmation to send to your Helper
			</Typography>
			<CircularProgress />
		</Container>
	)
}
