import {
  Button,
  Card,
  CardContent,
  Container,
  SxProps,
  Typography,
} from "@mui/material";
import "./styles.css";
import { Link } from "react-router-dom";
import {
  CHEATERS_LINK,
  HELPERS_LINK,
  INFO_SUFFIX,
} from "../../others/constants";

const cardContentSx: SxProps = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "500px",
  textAlign: "center",
};

export const ForCheatersForHelpersLandingSection = () => {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        gap: "20px",
      }}
    >
      <Card>
        <CardContent sx={cardContentSx}>
          <Typography
            variant="h4"
            sx={{ paddingBottom: "2vh", fontWeight: "bold" }}
          >
            For Cheaters
          </Typography>
          <Typography sx={{ paddingBottom: "2vh" }}>
            Would you like to find someone to help you during your exam?
            <br />
            <br />
            Let our Helpers... help you! <br />
          </Typography>
          <Link to={CHEATERS_LINK + INFO_SUFFIX}>
            <Button
              variant="contained"
              color="secondary"
              sx={{ color: "white" }}
            >
              Find Out How It Works!
            </Button>
          </Link>
        </CardContent>
      </Card>
      <Card>
        <CardContent sx={cardContentSx}>
          <Typography
            variant="h4"
            sx={{ paddingBottom: "2vh", fontWeight: "bold" }}
          >
            For Helpers
          </Typography>
          <Typography sx={{ paddingBottom: "2vh" }}>
            Would you like to help someone during their exam?
            <br />
            <br />
            Find a student in need!
            <br />
          </Typography>
          <Link to={HELPERS_LINK + INFO_SUFFIX}>
            <Button variant="contained" color="info" sx={{ color: "white" }}>
              Find Out How It Works!
            </Button>
          </Link>
        </CardContent>
      </Card>
    </Container>
  );
};
