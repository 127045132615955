import { Link, useParams } from 'react-router-dom'
import { useGetExamByKeys } from '../../hooks/useGetExamByKeys'
import React, { useEffect } from 'react'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import { API_URL, BASE_URL, STRIPE_API_URL } from '../../others/constants'
import InfoIcon from '@mui/icons-material/Info'
import {
	Button,
	CircularProgress,
	Container,
	Table,
	TableCell,
	TableRow,
	TextareaAutosize,
	Tooltip,
	Typography,
} from '@mui/material'
import HomeIcon from '@mui/icons-material/Home'
import { getColorBasedOnCheater } from '../../others/utils'
import { FaDiscord } from 'react-icons/fa'
import { useChechoutSession } from '../../hooks/useCheckoutSession'
import { useGetDiscordUsername } from '../../hooks/useGetDiscordUsername'
import { useAuth0 } from '@auth0/auth0-react'
export const ExamPage = () => {
	const p = useParams()
	const { user } = useAuth0()

	const [copied, setCopied] = React.useState(false)
	setTimeout(() => {
		setCopied(false)
	}, 2000)

	const {
		callApi: callGetExamByKeys,
		data,
		loading,
		error,
	} = useGetExamByKeys()

	useEffect(() => {
		callGetExamByKeys({
			url: API_URL + '/getExamByKeys',
			data: {
				pk_subject_language: p.languageSubject ?? '',
				sk_ts_userId: p.tsUserId ?? '',
			},
		})
	}, [callGetExamByKeys, p.languageSubject, p.tsUserId])

	const CustomTableCell = ({
		bold,
		children,
		right,
	}: {
		bold?: boolean
		right?: boolean
		children: React.ReactNode
	}) => {
		return (
			<TableCell
				sx={{
					fontSize: '1.5em',
					fontWeight: bold ? 'bold' : '',
					color: 'primary',
					textAlign: right ? 'right' : 'left',
				}}>
				{children}
			</TableCell>
		)
	}

	const {
		callApi: callApiStripe,
		data: dataStripe,
		loading: loadingStripe,
		error: errorStripe,
	} = useChechoutSession()

	const {
		callApi: callApiGetDiscordUsername,
		data: dataGetDiscordUsername,
		// loading: loadingGetDiscordUsername,
		// error: errorGetDiscordUsername,
	} = useGetDiscordUsername()

	useEffect(() => {
		callApiGetDiscordUsername({
			url: API_URL + '/getDiscordUsername',
			data: {
				email: user?.email ?? '',
			},
		})
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		console.log(dataStripe, loadingStripe, errorStripe)
		if (dataStripe && dataStripe.redirectUrl) {
			window.location.href = dataStripe.redirectUrl
		}
	}, [dataStripe, loadingStripe, errorStripe])

	console.log(dataStripe, loadingStripe, errorStripe)

	return (
		<Container
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				minHeight: '80vh',
				paddingTop: '3vh',
				gap: '2vh',
			}}>
			{loading && <CircularProgress />}
			{data && !loading && !error && (
				<Container
					sx={{
						width: '80%',
					}}>
					<Table>
						<TableRow>
							<CustomTableCell right>Created by:</CustomTableCell>
							<CustomTableCell>
								<Container
									sx={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
									}}>
									<Tooltip
										title={
											<Container
												sx={{
													textAlign: 'center',
												}}>
												<Typography>
													This exam was published by{' '}
													{data.cheater
														? 'a cheater'
														: 'an helper'}
													.
												</Typography>
												{data.cheater && (
													<Typography
														fontWeight={'bold'}>
														Can you help them?
													</Typography>
												)}
												{!data.cheater && (
													<Typography
														fontWeight={'bold'}>
														Can they help you?
													</Typography>
												)}
											</Container>
										}>
										<InfoIcon
											color={
												!data.cheater
													? 'secondary'
													: 'info'
											}
											sx={{
												paddingRight: '2vw',
											}}
										/>
									</Tooltip>
									<Typography sx={{ fontWeight: 'bold' }}>
										{data.cheater ? 'Cheater' : 'Helper'}
									</Typography>
								</Container>
							</CustomTableCell>
						</TableRow>
						<TableRow>
							<CustomTableCell right>Subject:</CustomTableCell>
							<CustomTableCell bold>
								{data.subject}
							</CustomTableCell>
						</TableRow>
						<TableRow>
							<CustomTableCell right>Language:</CustomTableCell>
							<CustomTableCell bold>
								{data.language}
							</CustomTableCell>
						</TableRow>
						<TableRow>
							<CustomTableCell right>
								Discord username:
							</CustomTableCell>
							<CustomTableCell bold>
								<Container
									sx={{
										display: 'grid',
										gridTemplateColumns: '30% 30% 30%',
										alignItems: 'center',
										gap: '20px',
									}}>
									<Typography sx={{ fontWeight: 'bold' }}>
										{data.discordUsername}
									</Typography>
									<Button
										color={getColorBasedOnCheater(
											!data.cheater
										)}
										sx={{
											color: 'white',
											minWidth: '160px',
											minHeight: '50px',
										}}
										onClick={() => {
											navigator.clipboard.writeText(
												data.discordUsername
											)
											setCopied(true)
										}}
										variant='contained'>
										{!copied && (
											<Typography
												sx={{ fontSize: '12px' }}>
												Copy Discord Username
											</Typography>
										)}
										{copied && (
											<Typography>Copied!</Typography>
										)}
									</Button>
									<Link
										style={{ textDecoration: 'none' }}
										target={'_blank'}
										to='https://discordapp.com/channels/@me/'>
										<Button
											color={
												!data.cheater
													? 'secondary'
													: 'info'
											}
											sx={{
												display: 'flex',
												flexDirection: 'row',
												alignItems: 'center',
												gap: '10px',
												color: 'white',
												minWidth: '160px',
												minHeight: '50px',
											}}
											variant='contained'>
											<Typography>Go to</Typography>
											<FaDiscord color={'white'} />
										</Button>
									</Link>
								</Container>
							</CustomTableCell>
						</TableRow>
						<TableRow>
							<CustomTableCell right>Price:</CustomTableCell>
							<CustomTableCell bold>€</CustomTableCell>
						</TableRow>
						<TableRow>
							<CustomTableCell right>Duration:</CustomTableCell>
							<CustomTableCell bold>
								{data.duration} minutes
							</CustomTableCell>
						</TableRow>
						{data.schoolName && (
							<TableRow>
								<CustomTableCell right>
									School Name:
								</CustomTableCell>
								<CustomTableCell bold>
									{data.schoolName}
								</CustomTableCell>
							</TableRow>
						)}
						{data.professor && (
							<TableRow>
								<CustomTableCell right>
									Professor Name:
								</CustomTableCell>
								<CustomTableCell bold>
									{data.professor}
								</CustomTableCell>
							</TableRow>
						)}
						{data.examInternalId && (
							<TableRow>
								<CustomTableCell right>
									Exam Internal Id:
								</CustomTableCell>
								<CustomTableCell bold>
									{data.examInternalId}
								</CustomTableCell>
							</TableRow>
						)}
						{data.tags && (
							<TableRow>
								<CustomTableCell right>Tags:</CustomTableCell>
								<CustomTableCell bold>
									{data.tags}
								</CustomTableCell>
							</TableRow>
						)}
						<TableRow>
							<CustomTableCell right>
								Description:
							</CustomTableCell>
							<CustomTableCell>
								<TextareaAutosize
									contentEditable={false}
									style={{ pointerEvents: 'stroke' }}
									value={data.description}
								/>
							</CustomTableCell>
						</TableRow>
					</Table>
					<Container
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							paddingTop: '20px',
						}}>
						{!data.cheater && (
							<Button
								color={getColorBasedOnCheater(!data.cheater)}
								sx={{
									color: 'white',
									minWidth: '160px',
									minHeight: '50px',
								}}
								type='submit'
								onClick={() => {
									callApiStripe({
										url: STRIPE_API_URL + '/stripe',
										data: {
											price: data.price,
											discordUsernameHelper:
												data.discordUsername,
											discordUsernameCheater:
												dataGetDiscordUsername.discordUsername ??
												'You',
											examSubject: data.subject,
											successUrl: BASE_URL + '/success',
											cancelUrl: window.location.href,
										},
									})
								}}
								variant='contained'>
								<Typography>Payment</Typography>
								<AttachMoneyIcon />
							</Button>
						)}
					</Container>
				</Container>
			)}
			{error && (
				<>
					<Typography>Something went wrong</Typography>
					<Link to='/'>
						<Button variant='contained'>
							<HomeIcon
								sx={{ color: 'primary', paddingRight: '2vw' }}
							/>
							<Typography>Go back</Typography>
						</Button>
					</Link>
				</>
			)}
		</Container>
	)
}
