import { Link } from "react-router-dom";
import logo from "../../static/logo.jpg";

export const Logo = () => {
  return (
    <Link
      to="/"
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        className="logo"
        style={{ width: 40, height: 40 }}
        src={logo}
        alt="logo"
      />
    </Link>
  );
};
