import {
	Card,
	CardContent,
	CardHeader,
	Container,
	Typography,
} from '@mui/material'
import { useCookies } from 'react-cookie'
import { green, orange } from '@mui/material/colors'
import { SavedExam } from './savedExam'

export const SavedPage = () => {
	const [cookies] = useCookies(['savedExamsCheaters', 'savedExamsHelpers'])
	const savedExamsStringsCheaters: string[] =
		cookies?.savedExamsCheaters ?? []
	const savedExamsStringsHelpers: string[] = cookies.savedExamsHelpers ?? []

	console.log({ savedExamsStringsCheaters, savedExamsStringsHelpers })

	const savedExamsCheaters = savedExamsStringsCheaters.map((examString) =>
		JSON.parse(examString)
	)
	const savedExamsHelpers = savedExamsStringsHelpers.map((examString) =>
		JSON.parse(examString)
	)
	// const savedExamsCheaters: any[] = []
	// const savedExamsHelpers: any[] = []

	return (
		<Container sx={{}}>
			<Typography variant='h3' sx={{ padding: '20px' }}>
				Saved Exams:
			</Typography>
			<Card
				sx={{
					borderLeft: '5px solid ' + orange[500],
					marginBottom: '20px',
					marginTop: '20px',
				}}>
				<CardHeader title='Cheaters' />
				<CardContent>
					{savedExamsCheaters.map((examKeys) => (
						<SavedExam
							cheater={true}
							pk_subject_language={examKeys.pk_subject_language}
							sk_ts_userId={examKeys.sk_ts_userId}
							color='secondary'
						/>
					))}
					{savedExamsCheaters.length === 0 && (
						<Typography>No exams from cheaters saved</Typography>
					)}
				</CardContent>
			</Card>
			<Card
				sx={{
					borderLeft: '5px solid ' + green[500],
					marginBottom: '20px',
					marginTop: '20px',
				}}>
				<CardHeader title='Helpers' />
				<CardContent>
					{savedExamsHelpers.map((examKeys) => (
						<SavedExam
							cheater={false}
							pk_subject_language={examKeys.pk_subject_language}
							sk_ts_userId={examKeys.sk_ts_userId}
							color='info'
						/>
					))}
					{savedExamsHelpers.length === 0 && (
						<Typography>No exams from helpers saved</Typography>
					)}
				</CardContent>
			</Card>
		</Container>
	)
}
