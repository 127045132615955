import { useEffect } from 'react'
import { API_URL } from '../../others/constants'
import { useGetExamByKeys } from '../../hooks/useGetExamByKeys'
import { ExamItem } from '../../components/examItem'

type SavedExamProps = {
	pk_subject_language: string
	sk_ts_userId: string
	color: 'primary' | 'secondary' | 'info'
	cheater: boolean
}
export const SavedExam = ({
	pk_subject_language,
	sk_ts_userId,
	cheater,
	color,
}: SavedExamProps) => {
	const {
		callApi: getExamByKeysCallApi,
		data,
		// error,
		loading,
	} = useGetExamByKeys()

	useEffect(() => {
		getExamByKeysCallApi({
			url: API_URL + '/getExamByKeys',
			data: { pk_subject_language, sk_ts_userId },
		})
	}, [getExamByKeysCallApi, pk_subject_language, sk_ts_userId])

	return (
		<>
			{data && (
				<ExamItem {...data} cheater={!cheater} loading={loading} />
			)}
		</>
	)
}
