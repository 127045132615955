import { Container, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { Logo } from '../logo'
import { LOGO_LABEL } from '../../text'

export const HeaderTitle = ({ color }: { color: string }) => {
	return (
		<Typography
			variant='h6'
			noWrap
			component='div'
			sx={{ width: 'fit-content' }}>
			<Link
				style={{
					textDecoration: 'none',
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'center',
					alignItems: 'center',
					maxWidth: '300px',
				}}
				to={'/'}>
				<Logo />
				<Container
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'start',
					}}>
					<Typography sx={{ paddingLeft: '10px', color }}>
						ABCheat
					</Typography>
					<Typography sx={{ fontSize: '12px', color }}>
						{LOGO_LABEL}
					</Typography>
				</Container>
			</Link>
		</Typography>
	)
}
